<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient78" x1="0.842" x2="0.245" y2="1">
     <stop offset="0" stop-color="#64e2d8"/>
     <stop offset="1" stop-color="#1691fa"/>
    </linearGradient>
   </defs>
   <g id="Twitter" transform="translate(-130 -208)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(130 208)" width="60"/>
    <path d="M26.916,9.453c.019.266.019.533.019.8,0,8.128-6.187,17.494-17.494,17.494A17.375,17.375,0,0,1,0,24.986a12.719,12.719,0,0,0,1.485.076,12.314,12.314,0,0,0,7.633-2.627,6.159,6.159,0,0,1-5.749-4.264,7.754,7.754,0,0,0,1.161.1,6.5,6.5,0,0,0,1.618-.209,6.149,6.149,0,0,1-4.93-6.034v-.076A6.192,6.192,0,0,0,4,12.727,6.158,6.158,0,0,1,2.094,4.5a17.477,17.477,0,0,0,12.678,6.434,6.941,6.941,0,0,1-.152-1.409A6.154,6.154,0,0,1,25.26,5.322a12.105,12.105,0,0,0,3.9-1.485,6.132,6.132,0,0,1-2.7,3.388A12.326,12.326,0,0,0,30,6.274,13.217,13.217,0,0,1,26.916,9.453Z" data-name="Icon awesome-twitter" id="Icon_awesome-twitter" style="fill: url(#linear-gradient78);" transform="translate(145 222.619)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>